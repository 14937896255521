//
// _nav.scss
//

.nav-tabs,.nav-pills {
  > li {
      > a {
      color: var(--#{$prefix}secondary-color);
      font-weight: 500;
    }
  }
}

.nav-pills {
  > a {
      color: var(--#{$prefix}secondary-color);  
      font-weight: 500;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}border-color);

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}
